import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  DocumentConsumerRegistration,
  DocumentConsumerRegistrationStatus,
} from 'src/app/core/models/document-consumer-registration';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import {
  DocumentConsumerRegFilter,
  DocumentConsumerRegService,
} from './../document-consumer-reg.service';
import { DocumentConsumerRegConsumerService } from 'src/app/document-consumer-reg-consumer/document-consumer-reg-consumer.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading = true;
  documents: DocumentConsumerRegistration[];
  total = 0;
  filter = new DocumentConsumerRegFilter();
  documentStatus: string[];

  minDate = new Date(2023, 5, 1);

  constructor(
    private service: DocumentConsumerRegService,
    private documentConsumerRegConsumerService: DocumentConsumerRegConsumerService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.documentStatus = Object.values(
      DocumentConsumerRegistrationStatus
    ) as string[];
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service
      .read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.read(page);
  }

  getDocumentStatusColor(data: DocumentConsumerRegistration) {
    return this.documentConsumerRegConsumerService.getDocumentValidationStatusColor(
      data.statusDocumentoGeradoConsumidor
    );
  }
}
