import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TypeDocument } from 'src/app/core/models/type-document';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import { encrypt } from '../../configs/encryption';
import { DocumentFilter, DocumentService } from '../document.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  filter = new DocumentFilter();
  visibleSidebarFilter = false;
  locale = new Locale();
  loading = false;
  documents = [];
  total = 0;
  typesDocument: TypeDocument[];

  constructor(
    private service: DocumentService,
    private typeDocumentService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.getTypeDocument();
  }

  getTypeDocument() {
    this.typeDocumentService.read().
      then((response) => {
        this.typesDocument = response.map(t => ({
          label: t.descTipoDocumentoEmissao, value: t.idTipoDocumentoEmissao
        }));
      }).catch(erro => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  resetFormFilter(form): void {
    form.reset();
  }

  encrypt(info: string): string {
    return encrypt(info);
  }

  exportExcelOrPdf(type: string): void {
    this.service.exportExcelOrPdf(type, this.filter)
      .then((data) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const fileName = `document-report.${type}`;

        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', downloadURL);
        link.download = fileName;
        link.click();
      })
  }
}
