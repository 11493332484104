import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExemptionReason } from './exemption-reason';

export enum DocumentConsumerRegistrationStatus {
  PENDENTE = 'PENDENTE',
  DIVERGENTE = 'DIVERGENTE',
  RECUSADO = 'RECUSADO',
  APROVADO = 'APROVADO'
}

export class DocumentConsumerRegistration {
  idDocumentoGeradoConsumidor: number;
  numDocumento: string;
  nifConsumidor: string;
  nomeConsumidor: string;
  nifEmissor: string;
  nomeEmissor: string;
  dtEmissaoDocumento: Date;
  totalValor: string;
  dtAlter: Date;
  dtCria: Date;
  files: File[];
  numero: string;
  serie: string;
  totalImposto: string;
  totalValorBaseTributavel: string;
  tbMotivoIsencao: ExemptionReason;
  desDocumentoGeradoConsumidor: string;
  cdMotivoIsencao: string;
  statusDocumentoGeradoConsumidor: DocumentConsumerRegistrationStatus;
  obsFinal: string;
  status: DocumentConsumerRegistrationStatus;
  idFinal: number;
  nifAlter: string;
  items: any[];
}

export class DocumentConsumerRegistrationForm {
  item = new DocumentItemConsumerRegistrationForm(this.fb).builder();

  constructor(private fb: UntypedFormBuilder) { }

  builder(): UntypedFormGroup {
    return this.fb.group({
      idDocumentoGeradoConsumidor: new FormControl({ value: null, disabled: true }),
      numDocumento: new FormControl({ value: null, disabled: true }, Validators.required),
      nifEmissor: new FormControl({ value: null, disabled: true }, Validators.required),
      nifConsumidor: new FormControl({ value: null, disabled: true }),
      nomeEmissor: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      nomeConsumidor: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      dtEmissaoDocumento: new FormControl({ value: new Date(), disabled: true }, Validators.required),
      dtAlter: new FormControl({ value: null, disabled: true }),
      dtCria: new FormControl({ value: null, disabled: true }),
      totalValor: new FormControl({ value: 0, disabled: true }, Validators.required),
      numero: new FormControl(null, Validators.required),
      serie: new FormControl(null, Validators.required),
      totalValorBaseTributavel: new FormControl({ value: 0, disabled: true }, [Validators.min(1), Validators.required]),
      totalImposto: new FormControl({ value: 0, disabled: true }, Validators.required),
      statusDocumentoGeradoConsumidor: new FormControl(null),

      desDocumentoGeradoConsumidor: new FormControl({ value: null, disabled: true }),
      obsFinal: new FormControl(),
      numeroFinal: new FormControl(),
      nifAlter: new FormControl({ value: null, disabled: true }),

      tbItensDocumentoGerados: this.fb.array([this.item])
    });
  }
}

export class DocumentItemConsumerRegistrationForm {
  constructor(private fb: UntypedFormBuilder) { }

  builder(): UntypedFormGroup {
    return this.fb.group({
      descItens: new FormControl,
      valorBaseTributavelItens: new FormControl(null, Validators.required),
      totalOrigin: new FormControl,
      tbTaxaAplicavel: this.fb.group({
        idTaxaAplicavel: new FormControl,
        descTaxaAplicavel: new FormControl,
        valorTaxaAplicavel: new FormControl(null, Validators.required)
      }),
      tbMotivoIsencao: this.fb.group({
        idMotivoIsencao: new FormControl,
        codigoMotivoIsencao: new FormControl(null, Validators.required),
        desMotivoIsencao: new FormControl,
      }),
      valorImposto: new FormControl({ value: 0, disabled: true }, Validators.required),
      valorItens: new FormControl({ value: 0, disabled: true }, Validators.required),
    })
  }
}