<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/document-consumer/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS REGISTADOS PELO ADQUIRENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="documentForm">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-d-flex justify-content-end">
              <p-tag
                [severity]="getDocumentStatusColor(document)"
                [value]="document.statusDocumentoGeradoConsumidor"
              ></p-tag>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Emitente</label>
              <input
                pInputText
                class="p-inputtext-sm"
                formControlName="nifEmissor"
              />
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do Emitente</label>
              <input
                pInputText
                class="p-inputtext-sm"
                formControlName="nomeEmissor"
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Adquirente</label>
              <input
                pInputText
                class="p-inputtext-sm"
                formControlName="nifConsumidor"
              />
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do Adquirente</label>
              <input
                pInputText
                class="p-inputtext-sm"
                formControlName="nomeConsumidor"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Referência</label>
              <input
                pInputText
                class="p-inputtext-sm"
                formControlName="numDocumento"
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Emissão</label>
              <p-calendar
                formControlName="dtEmissaoDocumento"
                [readonlyInput]="true"
                dateFormat="dd/mm/yy"
              ></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Registo</label>
              <p-calendar
                [readonlyInput]="true"
                dateFormat="dd/mm/yy"
                formControlName="dtCria"
              ></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data da Última Alteração</label>
              <p-calendar
                [readonlyInput]="true"
                dateFormat="dd/mm/yy"
                formControlName="dtAlter"
              ></p-calendar>
            </div>

            <div class="p-col-12">
              <hr />
              <div>
                <div class="p-grid p-mt-2">
                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '450px' }"
                  >
                    Descrição
                  </label>

                  <label class="p-col p-text-bold">
                    Subtotal<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Taxa ({{ siglaTipoImpostoToShow }})<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    {{ siglaTipoImpostoToShow }}<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Total<b class="red-text">*</b>
                  </label>
                </div>

                <div
                  class="p-grid"
                  *ngFor="
                    let item of document.items;
                    let i = index;
                    trackBy: trackByIndex
                  "
                >
                  <div
                    class="p-field p-col-fixed"
                    [ngStyle]="{ width: '450px' }"
                  >
                    <input
                      pInputText
                      class="p-inputtext-sm"
                      name="descItens"
                      [(ngModel)]="document.items[i].descItens"
                      [ngModelOptions]="{ standalone: true }"
                      maxlength="100"
                      disabled
                    />
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorBaseTributavelItens"
                      [(ngModel)]="document.items[i].valorBaseTributavelItens"
                      [ngModelOptions]="{ standalone: true }"
                      class="p-inputtext-sm"
                      #valorBaseTributavelItens="ngModel"
                      disabled
                    >
                    </p-inputNumber>
                  </div>

                  <div class="p-field p-col">
                    <input
                      pInputText
                      class="p-inputtext-sm"
                      name="descTaxaAplicavel"
                      [(ngModel)]="document.items[i].valorTaxaAplicavel"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Taxa"
                      disabled
                    />
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      class="p-inputtext-sm"
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorImposto"
                      [(ngModel)]="document.items[i].valorImposto"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Imposto"
                      disabled
                    >
                    </p-inputNumber>
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      class="p-inputtext-sm"
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorItens"
                      [(ngModel)]="document.items[i].valorItens"
                      [ngModelOptions]="{ standalone: true }"
                      disabled
                    >
                    </p-inputNumber>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div class="p-field p p-col-12 p-md-4">
              <label>Montante <b class="font-bold col-pink">*</b></label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValorBaseTributavel"
                formControlName="totalValorBaseTributavel"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>IVA</label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalImposto"
                formControlName="totalImposto"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Valor Total</label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValor"
                formControlName="totalValor"
                disabled
              >
              </p-inputNumber>
            </div>

            <div class="p-col-12">
              <p-tabView>
                <p-tabPanel header="Verificação">
                  <div class="p-fluid p-grid">
                    <div class="p-col-6">
                      <div class="p-grid">
                        <div class="p-field p-col-12 p-md-6">
                          <label>
                            Série <b class="font-bold pink-text">*</b>
                          </label>
                          <input
                            pInputText
                            class="p-inputtext-sm"
                            formControlName="serie"
                          />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                          <label>
                            Número Sequencial
                            <b class="font-bold pink-text">*</b>
                          </label>
                          <input
                            pInputText
                            class="p-inputtext-sm"
                            formControlName="numero"
                          />
                        </div>
                        <div class="p-field p-col-12">
                          <label>
                            Razão da Divergência
                            <b class="font-bold pink-text"></b>
                          </label>
                          <textarea
                            [rows]="6"
                            pInputTextarea
                            formControlName="desDocumentoGeradoConsumidor"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="p-col-6">
                      <div class="p-grid">
                        <div class="p-field p-col-12">
                          <label>
                            NIF do Técnico
                            <b class="font-bold pink-text">*</b>
                          </label>
                          <input
                            pInputText
                            class="p-inputtext-sm"
                            formControlName="nifAlter"
                          />
                        </div>
                        <div class="p-field p-col-12">
                          <label
                            >Notas do Técnico<b class="font-bold pink-text"></b
                          ></label>
                          <p-editor
                            formControlName="obsFinal"
                            [style]="{ height: '119px' }"
                            [readonly]="true"
                          >
                            <ng-template pTemplate="header">
                              <span class="ql-formats">
                                <button
                                  type="button"
                                  class="ql-bold"
                                  aria-label="Bold"
                                ></button>
                                <button
                                  type="button"
                                  class="ql-italic"
                                  aria-label="Italic"
                                ></button>
                                <button
                                  type="button"
                                  class="ql-underline"
                                  aria-label="Underline"
                                ></button>
                              </span>
                            </ng-template>
                          </p-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Anexo">
                  <div class="p-fluid p-grid">
                    <p-table
                      styleClass="p-datatable-sm p-datatable-responsive-demo"
                      [value]="files"
                      [responsive]="true"
                      [lazy]="true"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th [style]="{ width: '90%' }">Ficheiros</th>
                          <th [style]="{ width: '3em' }"></th></tr
                      ></ng-template>

                      <ng-template pTemplate="body" let-rowData>
                        <tr>
                          <td>
                            <span class="p-column-title">Ficheiro</span>
                            <span>{{
                              rowData.nomeArquivoDocumentoGerado
                            }}</span>
                          </td>

                          <td [style]="{ padding: '1px 4px' }">
                            <button
                              type="button"
                              mdbBtn
                              color="light"
                              size="sm"
                              tooltipPosition="left"
                              pTooltip="Transferir o ficheiro"
                              class="btn-block"
                              [style]="{ padding: '4px' }"
                              (click)="downloadFile(rowData)"
                            >
                              <i class="fa fa-download"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Elementos Adicionados Após a Validação">
                  <app-document-consumer-reg-elements-after-validation
                    *ngIf="finalDocument"
                    [document]="finalDocument"
                  ></app-document-consumer-reg-elements-after-validation>
                </p-tabPanel>
              </p-tabView>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/document-consumer/read"
              >
                VOLTAR
              </button>

              <button
                type="button"
                mdbBtn
                color="info"
                mdbWavesEffect
                pTooltip="Verificar fatura"
                tooltipPosition="top"
                (click)="verify()"
                [disabled]="
                  canValidateDocument(
                    document?.statusDocumentoGeradoConsumidor
                  ) || loading
                "
              >
                VERIFICAR
              </button>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<app-modal
  *ngIf="document?.nifEmissor"
  [showModal]="showValidationModal"
  [documentForm]="documentForm"
  [documentEmitterNif]="document?.nifEmissor"
  [canAccept]="canAccept"
  [validDocumentLoading]="validDocumentLoading"
  (onClose)="toggleValidationModal(false)"
  (onAccept)="accept($event)"
  (onRefuse)="refuse($event)"
></app-modal>
