<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS RECOLHIDOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <p-tabView>
        <p-tabPanel header="GERAL">
          <mdb-card-header class="mdb-card-header text-right">
            <div class="p-d-flex p-ai-top p-jc-between">
              <div class="">
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file-excel"
                  (click)="exportExcelOrPdf('xls')"
                  class="p-button-success m-2 btn-export"
                  pTooltip="Exportar em XLS"
                ></button>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file-pdf"
                  (click)="exportExcelOrPdf('pdf')"
                  class="p-button-warning m-2 btn-export"
                  pTooltip="Exportar em PDF"
                ></button>
              </div>
    
              <button
                type="button"
                mdbBtn
                size="md"
                color="light"
                mdbWavesEffect
                (click)="visibleSidebarFilter = true"
              >
                <mdb-icon fas icon="search"></mdb-icon>
                Filtrar
              </button>
            </div>
          </mdb-card-header>
    
          <mdb-card-body class="mdb-card-body">
            <p-table
              styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
              [value]="documents"
              [loading]="loading"
              [paginator]="true"
              [rows]="filter.rows"
              [lazy]="true"
              [totalRecords]="total"
              (onPage)="onChangePage($event)"
            >
              <ng-template pTemplate="caption">
                <p class="m-0 p-0">
                  Total de {{ total | number : "1.0-0" }} registos.
                </p>
              </ng-template>
    
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{ width: '10%' }">Tipo Documento</th>
                  <th [style]="{ width: '10%' }">Nº Documento</th>
                  <th [style]="{ width: '25%' }">Emitente</th>
                  <th [style]="{ width: '25%' }">Adquirente</th>
                  <th [style]="{ width: '6%' }">Data Emissão</th>
                  <th [style]="{ width: '10%' }">Data Registo</th>
                  <th [style]="{ width: '10%' }">Valor Total</th>
                  <th [style]="{ width: '3%' }"></th>
                </tr>
              </ng-template>
    
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td>
                    <span class="p-column-title">Tipo Documento</span>
                    {{ rowData.tbTipoDocumentoDescricao }}
                  </td>
    
                  <td>
                    <span class="p-column-title">Nº Documento</span>
                    {{ rowData.numDocumento }}
                  </td>
    
                  <td>
                    <span class="p-column-title">Emitente</span>
                    <span> {{ rowData.tbContribuinteNifEmissor }} </span>
                    -
                    {{ rowData.tbPessoaNomeEmissor }}
                  </td>
    
                  <td>
                    <span class="p-column-title">NIF Adquirente</span>
                    <span> {{ rowData.tbContribuinteNifConsumidor }} </span>
                    -
                    {{ rowData.tbPessoaNomeConsumidor }}
                  </td>
    
                  <td>
                    <span class="p-column-title">Data Emissão</span>
                    {{ rowData.dtEmissaoDocumento | date : "dd/MM/yyyy" }}
                  </td>
    
                  <td>
                    <span class="p-column-title">Data Registo</span>
                    {{ rowData.dtCria | date : "dd/MM/yyyy" }}
                  </td>
    
                  <td>
                    <span class="p-column-title"> Valor Total </span>
                    <strong>{{
                      rowData.totalValor | number : "0.1-2" : "pt"
                    }}</strong>
                  </td>
    
                  <td [style]="{ padding: '1px 4px' }">
                    <button
                      type="button"
                      mdbBtn
                      color="info"
                      size="sm"
                      block="true"
                      pTooltip="Ver mais informações"
                      tooltipPosition="left"
                      [style]="{ padding: '4px 0' }"
                      [routerLink]="[
                        '/document/read',
                        encrypt(rowData.idDocumentoGerado)
                      ]"
                    >
                      <mdb-icon fas icon="search"></mdb-icon>
                    </button>
                  </td>
                </tr> </ng-template
              >
            </p-table>
          </mdb-card-body>
        </p-tabPanel>

        <p-tabPanel header="DOCUMENTOS EMITIDOS PARA O ESTADO">
          <!-- <app-read-state></app-read-state> -->
        </p-tabPanel>
      </p-tabView>
    </mdb-card>
  </div>
</div>

<!-- FILTERS -->
<p-sidebar
  [(visible)]="visibleSidebarFilter"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '450px' }"
>
  <h2 style="font-weight: normal">Filtros</h2>
  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="read()">
    <div class="p-fluid">
      <div class="p-grid p-col-12 p-pb-2">
        <div class="p-col-6">
          <button
            type="button"
            mdbBtn
            color="red"
            size="md"
            block="true"
            (click)="resetFormFilter(formFilter)"
          >
            Limpar
          </button>
        </div>

        <div class="p-col-6">
          <button type="submit" mdbBtn color="info" size="md" block="true">
            Filtrar
          </button>
        </div>
        <hr />
      </div>

      <p-scrollPanel [style]="{ width: '100%', height: '80vh' }">
        <div class="p-field">
          <span class="p-float-label">
            <input
              class="p-inputtext-sm"
              pInputText
              name="nifEmissor"
              [(ngModel)]="filter.nifEmissor"
            />
            <label for="float-input">NIF Emitente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              class="p-inputtext-sm"
              pInputText
              name="nifConsumidor"
              [(ngModel)]="filter.nifConsumidor"
            />
            <label for="float-input">NIF Adquirente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              class="p-inputtext-sm"
              pInputText
              name="numDocumento"
              [(ngModel)]="filter.numDocumento"
            />
            <label for="float-input">Número Documento</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              class="p-inputtext-sm"
              pInputText
              name="numSerieDocumento"
              [(ngModel)]="filter.numSerieDocumento"
            />
            <label for="float-input">Número Série</label>
          </span>
        </div>

        <div class="p-grid p-field">
          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-calendar
                class="p-inputtext-sm"
                name="dtEmissaoDocumentoDe"
                [(ngModel)]="filter.dtEmissaoDocumentoDe"
                dateFormat="dd/mm/yy"
                [locale]="locale.pt"
                appendTo="body"
              >
              </p-calendar>
              <label for="float-input">Data Inicial</label>
            </span>
          </div>

          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-calendar
                class="p-inputtext-sm"
                name="dtEmissaoDocumentoAte"
                [(ngModel)]="filter.dtEmissaoDocumentoAte"
                dateFormat="dd/mm/yy"
                [locale]="locale.pt"
                [focusTrap]="false"
                appendTo="body"
              >
              </p-calendar>
              <label for="float-input">Data Final</label>
            </span>
          </div>
        </div>

        <div class="p-field p-d-flex ai-center">
          <p-triStateCheckbox
            name="hasTax"
            [(ngModel)]="filter.hasTax"
          ></p-triStateCheckbox>
          <label class="ml-2">Sujeito ao imposto</label>
        </div>

        <div class="p-grid p-field">
          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-inputNumber
                name="minValue"
                [(ngModel)]="filter.minValue"
                mode="decimal"
                locale="de-DE"
                [minFractionDigits]="2"
              >
              </p-inputNumber>
              <label for="float-input">Valor Mínimo</label>
            </span>
          </div>

          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-inputNumber
                name="maxValue"
                [(ngModel)]="filter.maxValue"
                mode="decimal"
                locale="de-DE"
                [minFractionDigits]="2"
              >
              </p-inputNumber>
              <label for="float-input">Valor Máximo</label>
            </span>
          </div>
        </div>

        <div class="p-field">
          <p-dropdown
            class="p-inputtext-sm"
            [options]="typesDocument"
            name="typeDocumentId"
            [(ngModel)]="filter.typeDocumentId"
            placeholder="Tipo Documento"
            [showClear]="true"
          >
          </p-dropdown>
        </div>
      </p-scrollPanel>
    </div>
  </form>
</p-sidebar>
